import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`Hamstring Curls 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`50 Wall Balls (20/14)`}</p>
    <p>{`15 Cleans (135/95)`}</p>
    <p>{`50 Wall Balls`}</p>
    <p>{`10 Cleans (185/135)`}</p>
    <p>{`50 Wall Balls`}</p>
    <p>{`5 Cleans (225/155)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`OR`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Team Series Event 3`}</em></strong></p>
    <p>{`50 Synchronized Wall Balls`}</p>
    <p>{`30 Cleans (shared reps)`}</p>
    <p>{`50 Synchronized Wall Balls`}</p>
    <p>{`20 Cleans (shared reps)`}</p>
    <p>{`50 Synchronized Wall Balls`}</p>
    <p>{`10 Cleans (shared reps)`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`Scaled weights are 14/10`}{`#`}{` wall ball and 95/65, 135/95, 155/105
cleans.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`On October 28th The Ville will be hosting a fundraising wod for the
family of LMPD Officer Jason Schweitzer who was tragically killed by a
drunk driver in Lexington, Ky.  You can donate and purchase a shirt at
the link below.  CrossFit 222 will show up as the host but you can
choose your shirt size and our location when checking out.  The shirts
will be brought to The Ville for you.  Please sign up soon and show your
support for one of our LMPD officers and his family!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.  More
info to come. Truegritcomp.wordpress.com.  Workout 1 to be posted
soon!***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      